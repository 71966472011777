import {Component, Input} from '@angular/core';

@Component({
  selector: 'canvas-client-tags',
  template: `
    @for(tag of tags; track tag.name) {
      <div [style.background-color]="tag.color" [matTooltip]="tag.description" class="text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
        {{tag.name}}
      </div>
    }
  `
})
export class ClientTagsComponent {
  @Input() tags = [];

}
