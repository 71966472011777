<div class="flex flex-row justify-between border-b border-b-slate-300 hover:bg-gray-100 p-1 h-10">
  <div class="flex flex-row items-center gap-3">
    <div class="flex flex-row items-center gap-1">
        <mat-icon svgIcon="mat:attach_file"></mat-icon>
        <a (click)="downloadFile()" class="font-light text-blue-400 hover:underline cursor-pointer">{{file.name}}</a>
        <div>{{file.description}}</div>
    </div>
  </div>
  <div class="flex flex-row items-center gap-2">
<!--    <a mat-icon-button color="warn"><mat-icon svgIcon="mat:delete"></mat-icon></a>-->
    <span>{{file.created | date : 'dd.MM.yyyy'}}</span>
  </div>
</div>
