<div class="flex flex-row justify-between border-b border-b-slate-300 hover:bg-gray-100 p-2">
  <div class="flex flex-row items-center gap-3">
    <div class="flex flex-row items-center gap-1">
        <mat-icon svgIcon="mat:checklist"></mat-icon>
        <a routerLink="/limitace/detail/{{limitbase.id}}" class="font-light text-blue-400 hover:underline">Aukce č.{{limitbase.auction.auctionNumber}} - <span class="font-bold">{{limitbase.rowNumber}}</span></a>
      <div class="flex flex-row flex-wrap items-stretch ml-3">
<!--        <ng-container class="flex" *ngFor="let pic of contract.pictures">-->
<!--          <a routerLink="/dila/detail/{{pic.id}}" (mouseenter)="op.toggle($event)" (mouseleave)="op.hide()" class="label label-primary inline">{{pic.id}}{{contract.prefixNumber}}</a>-->
<!--        </ng-container>-->
      </div>
    </div>
  </div>
  <div class="flex flex-row-reverse items-center gap-2">
    <div class="w-20">{{limitbase.datetime | date : 'dd.MM.yyyy'}}</div>
    <button routerLink="/limitace/detail/{{limitbase.id}}" class="leading-5 text-xs flex flex-row items-center px-3 py-1 bg-primary hover:bg-blue-600 rounded-2xl gap-2 text-white">Detail</button>
    <a [routerLink]="'/klienti/uprava/limitace/' + limitbase.clientId" [queryParams]="{auctionId: limitbase.auctionId}" class="leading-5 text-xs flex flex-row items-center px-3 py-1 bg-primary hover:bg-blue-600 rounded-2xl gap-2 text-white">Upravit</a>
  </div>
</div>
