import {firstValueFrom} from "rxjs";

export function sanitizeFilename(filename: string) {
  return filename.replace(/[^\w\s\d\-_~,;\[\]\(\).]/g, '').replace(/\.{2,}/g, '').replace(/\s+/g, '_');
}

export function openLink(blob: Blob | MediaSource, downloadName: string | undefined = undefined, openInTab: boolean = true, target: string = '') {
  try {
    const pdfURL = URL.createObjectURL(blob);
    if(openInTab) {
      window.open(pdfURL);
    }else {
      const link = document.createElement('a');
      link.href = pdfURL
      if(downloadName) {
        link.download = downloadName;
      }
      link.target = target;
      document.body.append(link);
      link.click();
      link.remove();
    }
  } catch (e) {
    console.log(e);
  }
}
