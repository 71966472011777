import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuctionService, AuctionsSelect, AuctionTerm, ModxPicturecontractWithRelations} from '../../../../@backend';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {firstValueFrom} from 'rxjs';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'canvas-auction-select',
  template: `
    @if (!loading) {
    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
      <mat-label>Aukce</mat-label>
      <mat-select (valueChange)="onAuctionSelected($event)" [(ngModel)]="selectAuctionId" [placeholder]="'Vyberte aukci'">
        @if(displayAll) {
          <mat-option>{{defaultText}}</mat-option>
        }
        @for(auc of auctions; track auc){
        <mat-option [value]="auc.id" class="text-xs">
          <div class="flex flex-row items-center">
            @switch (auc.auctionType) {
              @case (1) {
                <mat-icon color="primary" svgIcon="mat:account_balance"></mat-icon>
              }
              @case (2) {
                <mat-icon color="primary" svgIcon="mat:public"></mat-icon>
              }
              @case (3) {
                <mat-icon color="primary" svgIcon="mat:brush"></mat-icon>
              }
            }
            <div>
              @if(auc?.auctionNumber){{{auc?.auctionNumber}} - }
              {{auc?.auctionName}}
              ({{auc?.term}})
            </div>
          </div>
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  `
})
export class AuctionSelectComponent implements OnInit {

  @Output() auctionId = new EventEmitter<AuctionsSelect>();
  @Input() selectAuctionId?: number = null;
  @Input() displayAll?: boolean = true;
  @Input() defaultText?: string = 'Všechny aukce';

  loading = true;
  auctions: AuctionsSelect[];
  selectedAuction: AuctionsSelect;

  constructor(private auctionService: AuctionService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.auctions = await firstValueFrom(this.auctionService.auctionControllerGetAuctionsForSelect());
    this.loading = false;

  }


  onAuctionSelected(auctionId: number) {
    if(auctionId) {
      const auc = this.auctions.find(i => i.id === auctionId);
      this.auctionId.emit(auc);
    }else {
      this.auctionId.emit(undefined);
    }

  }


}
