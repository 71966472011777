import {Component, Input, OnInit} from '@angular/core';
import {LimitSummaryItems} from '../../../../@backend';

@Component({
  selector: 'canvas-limitation-summarize-card',
  templateUrl: './limitation-summarize-card.component.html',
})
export class LimitationSummarizeCardComponent implements OnInit {
  @Input() limitSummary: LimitSummaryItems;
  constructor() { }

  ngOnInit(): void {
  }


}
