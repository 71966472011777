<div class="flex flex-col w-full p-2">

  <div class="flex flex-row justify-between">
    <div class="flex flex-col">
      <div class="font-bold text-2xl">{{telephonist.title}} {{telephonist.firstname}} {{telephonist.lastname}}</div>
      <div class="text-sm">Počet limitních lístků: {{telephonist.limitbases.length}} </div>
    </div>
    <div class="flex flex-row">
      <a (click)="downloadTelephonistExcel(telephonist.id, auctionId)" mat-icon-button matTooltip="Export karty telefonisty">
        <mat-icon svgIcon="mat:download"></mat-icon>
      </a>
    </div>
  </div>

  <div class="flex flex-col">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full max-w-fit text-left text-sm font-light table-fixed">
              <thead class="border-b font-medium dark:border-neutral-500">
              <tr>
                <th scope="col" class="w-32 px-1 py-1">Přiřadit</th>
                <th scope="col" class="w-80 px-1 py-1">Číslo limitního lístku</th>
                <th scope="col" class="min-w-1/5 w-1/5 px-1 py-1">Klient</th>
                <th scope="col" class="min-w-4/5 w-4/5 px-1 py-1">Limity</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let lb of telephonist.limitbases;let last = last;" class="border-b transition duration-300 ease-in-out hover:bg-gray-100"
                  [ngClass]="lb.limits.length === 1 ? 'bg-blue-100 dark:hover:bg-blue-200' : 'dark:hover:bg-gray-300'">
                <td class="whitespace-nowrap px-1 py-1 font-medium">
                  <button (click)="assignLimitbaseToTelephonist(lb.id, lb.auctionId, lb.telephonistId)" mat-icon-button color="primary" matTooltip="Otevře dialogové, kde lze změnit, či zadat telefonistu pro dané LL">
                    <mat-icon svgIcon="mat:assignment_return"></mat-icon>
                  </button>
                </td>
                <td class="whitespace-nowrap"><a [ngClass]="last && highlightLastRow ? 'bg-amber-300 dark:hover:bg-amber-400' : ''" mat-stroked-button routerLink="/limitace/detail/{{lb.id}}"><span class="font-bold">{{lb.rowNumber}}</span></a></td>
                <td class="whitespace-nowrap px-1 py-1">
                  <a routerLink="/klienti/detail/{{lb?.client.id}}" class="hover:underline"><canvas-client-basic [client]="lb?.client"></canvas-client-basic></a>
                </td>
                <td class="whitespace-nowrap px-1 py-1 w-3/4 flex flex-row flex-wrap">

                  <div class="flex" *ngFor="let l of lb?.limits" (mouseenter)="onMouseEnterLimit(telephonist.id, l.item.catalogNumber)" (mouseleave)="onMouseLeaveLimit(telephonist.id, l.item.catalogNumber)">

                    <ng-container *ngIf="checkIfCrossingItem(l.item.catalogNumber);else safeLimit">

                      <div *ngIf="l.type === 1" [attr.data-catalognumber]="telephonist.id + ':'+l.item.catalogNumber" class="mr-1 bg-orange-500 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-orange-500 dark:text-blue-50 border border-orange-600">
                        <mat-icon svgIcon="mat:phone"></mat-icon>
                        {{l.item?.catalogNumber}}
                        <mat-icon svgIcon="mat:close"  class="text-shadow-sm text-black"></mat-icon>
                      </div>

                      <div *ngIf="l.type === 2" [attr.data-catalognumber]="telephonist.id + ':'+l.item.catalogNumber" class="mr-1 bg-orange-500 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-orange-500 dark:text-white border border-orange-600">
                        <mat-icon svgIcon="mat:back_hand"></mat-icon>
                        {{l.item?.catalogNumber}}
                        <mat-icon svgIcon="mat:close" color="warn"></mat-icon>
                      </div>
                    </ng-container>
                    <ng-template #safeLimit>

                      <div *ngIf="l.type === 1" class="mr-1 bg-green-700 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-green-700 dark:text-blue-50 border border-green-500">
                        <mat-icon svgIcon="mat:phone"></mat-icon>
                        {{l.item?.catalogNumber}}
                        <ng-container *ngIf="checkIfCloseNextItem(l.item.catalogNumber)">
                          <mat-icon svgIcon="mat:priority_high" color="warn"></mat-icon>
                        </ng-container>
                      </div>

                      <div *ngIf="l.type === 2" class="mr-1 bg-blue-50 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-blue-50 dark:text-blue-500 border border-blue-300">
                        <mat-icon svgIcon="mat:back_hand"></mat-icon>
                        {{l.item?.catalogNumber}}
                        <ng-container *ngIf="checkIfCloseNextItem(l.item.catalogNumber)">
                          <mat-icon svgIcon="mat:priority_high" color="warn"></mat-icon>
                        </ng-container>
                      </div>
                    </ng-template>

                  </div>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
