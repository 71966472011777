import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModxClient, ModxContractWithRelations} from '../../../../@backend';
import {Router, RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AuthorComponent} from "../author/author.component";
import {PhotoSecuredPipe} from "../../pipes/photoSecured.pipe";
import {ComponentsModule} from "../components.module";

@Component({
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, OverlayPanelModule, RouterModule, AuthorComponent, PhotoSecuredPipe],
  selector: 'canvas-client-contract-card',
  template: `
    <div class="flex md:flex-row flex-col justify-between border-b border-b-slate-300 hover:bg-gray-100 p-2 items-center">
      <div class="flex flex-row items-center gap-3">
        <div class="flex flex-row items-center gap-1">
          <div class="w-7"><mat-icon svgIcon="mat:work"></mat-icon></div>
          <div class="">
            <a routerLink="/klienti/smlouva/detail/{{contract.id}}" class="font-light text-blue-400 hover:underline">Smlouva <span class="font-bold">{{contract.id}}{{contract.prefixNumber}}</span></a>
          </div>
          <div class="flex flex-row flex-wrap items-stretch ml-3 gap-1">
            @for (pic of contract.pictures; track pic.id) {
              <div class="flex">
                <p-overlayPanel class="flex flex-col items-center" #op>
                  <img [alt]="pic.name" class="block object-cover object-center cursor-pointer" width="200" height="200"
                       [src]='pic.photo|photoSecured:"1":"/plain/rs:fill:200:200:1/g:no/":pic.shared | async' />
                  <h3 class="font-bold text-base" [innerHTML]="pic.name"></h3>
                  <kodl-author class="text-sm" [authors]="pic?.authors"></kodl-author>
                </p-overlayPanel>
                <a routerLink="/dila/detail/{{pic.id}}" (mouseenter)="op.toggle($event)" (mouseleave)="op.hide()" class="label label-primary inline">{{pic.id}}{{contract.prefixNumber}}</a>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="flex flex-row-reverse items-center gap-2 w-48">
        @if (selectClientContractMode) {
          <a (click)="onSelectContract()" class="leading-5 text-sm flex flex-row items-center px-3 py-1 bg-primary hover:bg-blue-600 rounded-2xl gap-2 text-white cursor-pointer">Vybrat smlouvu</a>
        } @else {

          <div class="w-20">{{contract.datetime | date : 'dd.MM.yyyy'}}</div>
          <a (click)="onAddItemToContract()" class="leading-5 text-xs flex flex-row items-center px-3 py-1 bg-primary hover:bg-blue-600 rounded-2xl gap-2 text-white cursor-pointer">Přidat dílo</a>
        }
      </div>
    </div>
  `
})
export class ClientContractCardComponent {

  @Input() contract: ModxContractWithRelations;
  @Input() clientId?: number | undefined;
  @Input() selectClientContractMode?: boolean = false;

  @Output() selectedContract? = new EventEmitter<ModxContractWithRelations>();

  constructor(private router: Router) { }

  onAddItemToContract() {
    if(this.clientId) {
      this.router.navigate(['dila/nove-dilo'], { queryParams: { contractId: this.contract.id, redirectTo: 'client', redirectId: this.clientId } });
    }else{
      this.router.navigate(['dila/nove-dilo'], { queryParams: { contractId: this.contract.id, redirectTo: 'client', redirectId: this.contract.id } });
    }

  }

  onSelectContract() {
    this.selectedContract.emit(this.contract);
  }
}
