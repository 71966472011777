import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LimitationService} from '../../../../@backend';
import {MatRadioChange} from "@angular/material/radio";

@Component({
  selector: 'canvas-limitation-assigned-select',
  templateUrl: './limitation-assigned-select.component.html',
})
export class LimitationAssignedSelectComponent implements OnInit {

  @Output() value = new EventEmitter<number>();

  defaultValue: number | null = null;

  constructor(private limitationService: LimitationService) { }

  async ngOnInit(): Promise<void> {
  }


  onValueSelect($event: MatRadioChange) {
    console.log($event);
    this.value.emit($event.value);
  }


}
