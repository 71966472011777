import {Component, Input, OnInit} from '@angular/core';
import {ModxLimitWithRelations} from '../../../../@backend';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
  selector: 'canvas-limitation-basic',
  templateUrl: './limitation-basic.component.html',
})
export class LimitationBasicComponent implements OnInit {
  @Input() limitation: ModxLimitWithRelations;
  @Input() index: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToLimitationEdit() {
    this.router.navigate(['/klienti/uprava/limitace/' + this.limitation.limitbase.clientId], { queryParams: { auctionId: this.limitation.limitbase.auctionId }});
  }

}
