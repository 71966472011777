import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ClientService,
  ModxClient, ModxClientWithRelations, ModxContractWithRelations, ModxContractfileWithRelations
} from '../../../../@backend';
import {CommonModule} from "@angular/common";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {firstValueFrom, Observable} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {ClientBasicComponent} from "../client-basic/client-basic.component";
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {map, startWith} from "rxjs/operators";
import {ClientContractCardComponent} from "../client-contract-card/client-contract-card.component";

@Component({
  selector: 'canvas-client-select-contract',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatSelectModule, MatIconModule, ClientBasicComponent, MatAutocompleteModule, MatInputModule, ClientContractCardComponent],
  template: `
    @if (!loading) {
    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
      <mat-label>Klient</mat-label>
      <input type="text" placeholder="Vyhledejte klienta"  aria-label="Klient" matInput
             [formControl]="searchControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onClientSelect($event)">
        @for (client of filteredClients | async; track client.id) {
        <mat-option [value]="client" class="text-xs">
            <canvas-client-basic [client]="client"></canvas-client-basic>
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

      @if (clientContracts){
        @if (clientContracts?.contracts?.length > 0) {
          <div class="flex flex-col w-full mt-5">
            @for (contract of clientContracts.contracts; track contract.id) {
              <canvas-client-contract-card [contract]="contract" [clientId]="null" [selectClientContractMode]="true" (selectedContract)="onSelectedContract($event)"></canvas-client-contract-card>
            }
          </div>
        }@else {
          <div class="flex w-full text-center p-4 text-orange-500">Klient nemá žádné smlouvy</div>
        }
      }
    }
  `
})
export class ClientSelectContractComponent implements OnInit {

  @Output() contract = new EventEmitter<ModxContractWithRelations>();
  @Input() selectedClientId?: number = null;
  @Input() clients?: ModxClient[];
  @Input() required?: boolean = false;

  searchControl = new FormControl('');
  filteredClients: Observable<ModxClient[]>;

  clientContracts: ModxClientWithRelations;
  loading = true;

  constructor(private clientService: ClientService) { }

  async ngOnInit(): Promise<void> {

    this.loading = true;
    if(this.clients === undefined) {
      this.clients = await firstValueFrom(this.clientService.clientControllerGetClientsForSelect());
    }

    this.filteredClients = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.loading = false;


  }

  async onClientSelect(clientSelected: MatAutocompleteSelectedEvent) {
    // const client = this.clients.find(i => i.id === clientId);
    // this.client.emit(clientSelected.option.value);

    // LOAD CLIENT CONTRACTS
    this.clientContracts = await firstValueFrom(this.clientService.clientControllerGetClientContracts(clientSelected.option.value.id));
  }

  private _filter(value: string): ModxClient[] {
    if(typeof value !== 'string' || value === '' || value.length < 3) {
      this.clientContracts = undefined;
      return [] as ModxClient[];
    }
    return this.clients.filter(client => {
      if(client?.firstname && client?.lastname) {
        return `${client.firstname} ${client.lastname}`.toLowerCase().includes(value.toLowerCase());
      } else {
        return false;
      }
    });
  }

  displayFn(client: ModxClient): string {
    return client ? client.firstname + ' ' + client.lastname : '';
  }

  onSelectedContract($event: ModxContractWithRelations) {
    this.contract.emit($event);
  }
}
