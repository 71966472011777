import {Component, Input, OnInit} from '@angular/core';
import {AuctionTermWithRelations} from '../../../../@backend';

@Component({
  selector: 'canvas-item-labels',
  template: `
    <div class="flex flex-row gap-2">
      <a class="label label-primary inline" matTooltip="ID Obrazu">{{itemId}}{{prefixNumber}}</a>
      <span class="label label-katalogove-cislo inline" matTooltip="Katalogové číslo">
        @if(catalogNumber) {
          <span>{{catalogNumber}}</span>
        } @else {
          <span>-</span>
        }
        /
        @if(auctionNumber && auctionNumber.length > 0 && auctionNumber[0].auctionNumber !== null) {
          <span>{{auctionNumber[0].auctionNumber}}</span>
        } @else {
          <span>-</span>
        }
  </span>
      @if(limitNumber) {
        <span class="label label-limitni-cislo inline" matTooltip="Limitní číslo">{{limitNumber}}</span>
      }
      @if (shared && showSharedLabel) {
        <span class="label label-shared inline items-center" matTooltip="Dílo je veřejné">
          <mat-icon class="icon h-auto" style="scale: 0.7; margin-top: -3px;" svgIcon="mat:public"></mat-icon>
      </span>
      }
    </div>
  `
})
export class ItemLabelsComponent implements OnInit {

  @Input() itemId: number;
  @Input() prefixNumber: string;
  @Input() shared?: boolean = false;

  @Input() catalogNumber: number | null;
  @Input() auctionNumber: Array<AuctionTermWithRelations> | undefined;
  @Input() limitNumber: number | null = null;

  @Input() showSharedLabel?: boolean = true;

  constructor() { }

  ngOnInit(): void {}

}
