import {Component, Input} from '@angular/core';
import {
  AuctionTerm, ModxPicturecontractWithRelations
} from '../../../../@backend';

@Component({
  selector: 'canvas-client-biditem-card',
  templateUrl: './client-biditem-card.component.html'
})
export class ClientBiditemCardComponent {
  @Input() bidItem: ModxPicturecontractWithRelations;
  @Input() auction: AuctionTerm;
  @Input() isWinner: boolean;
}
