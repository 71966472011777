import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ModxTag, TagService} from "../../../../@backend";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'canvas-tag-add-edit-modal',
  templateUrl: './tag-add-edit-modal.component.html',
})
export class TagAddEditModalComponent implements OnInit{

  tagId?: number;
  tag?: ModxTag;

  hexColor: string;

  form = this.fb.group({
    name: ['', Validators.required],
    description: [null],
    color: ['', Validators.required],
  });
  private loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TagAddEditModalComponent>,
    private fb: FormBuilder,
    private tagService: TagService
  ) {
    if(data?.tagId) {
      this.tagId = data.tagId;
    }else{
      this.tag = {} as ModxTag;
    }

  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if(this.tagId) {
      this.tag = await firstValueFrom(this.tagService.tagControllerGetTagById(this.tagId));
      this.hexColor = this.tag.color;
      this.form.patchValue(this.tag);
    }
    this.loading = false;

    this.form.controls['color'].valueChanges.subscribe(value => {
      this.hexColor = value;
    });
  }

  async save() {
    if(this.form.valid) {
      try {
        if(this.tagId) {
          const updatedTag = this.form.value as ModxTag;
          updatedTag.id = this.tagId;
          const res = await firstValueFrom(this.tagService.tagControllerUpdateTag(this.tagId, updatedTag));
        }else {
          const newTag = this.form.value as ModxTag;
          const res = await firstValueFrom(this.tagService.tagControllerCreateTag(newTag));
        }
        this.dialogRef.close(true);
      }catch (e) {
        console.error(e);
      }
    }
  }

}
