import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemService, ModxContracttemplate, ModxPicturecontractWithRelations} from '../../../../@backend';
import {dropdownAnimation} from '../../../../@vex/animations/dropdown.animation';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ItemChangeContractModalComponent} from "../item-change-contract-modal/item-change-contract-modal.component";
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog.component";
import {firstValueFrom} from "rxjs";
import { itemStates } from '../../utils/itemStates';

@Component({
  selector: 'canvas-item-basic',
  templateUrl: './item-basic.component.html',
  animations: [dropdownAnimation]
})
export class ItemBasicComponent implements OnInit {
  @Input() item: ModxPicturecontractWithRelations;

  @Input() redirectTo?: 'contract' | 'itemlist' = 'itemlist';
  @Input() redirectId?: number | undefined = undefined;

  @Input() showStatus?: boolean = false;

  @Input() showChangeContractBtn?: boolean = false;
  @Input() showDeletetBtn?: boolean = false;

  @Output() onTransferedItem? = new EventEmitter<number>();
  @Output() onDeletedItem? = new EventEmitter<number>();

  jobsDone = 0;
  jobsUnfinished = 0;
  jobsProgress = 0;
  jobsLength = 0;

  isSubPanelOpened = false;
  protected readonly itemStates = itemStates;

  data: any;
  options: any;

  constructor(private router: Router, private dialog: MatDialog, private itemService: ItemService) { }

  ngOnInit(): void {
    this.countProgress();

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    this.data = {
      labels: ['Hotovo', 'Přiděleno'],
      datasets: [
        {
          data: [this.jobsDone, this.jobsUnfinished],
          backgroundColor: ['#CCCCCC', '#000000'],
          hoverBackgroundColor: ['#CCCCCC', '#000000']
        }
      ]
    };


    this.options = {
      cutout: '80%',
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      }
    };
  }

  countProgress(): void {
    if(this.item?.jobs) {
      this.jobsLength = this.item.jobs.length;

      for(const job of this.item.jobs) {
        if(job.status === 3) {
          this.jobsDone += 1;
        }else {
          this.jobsUnfinished += 1;
        }
      }
      this.jobsProgress = ((this.jobsDone/this.jobsLength)*100);
    }
  }

  openSubpanel() {
    this.isSubPanelOpened = true;
  }

  closeSubpanel() {
    this.isSubPanelOpened = false;
  }

  onEdit() {
    this.router.navigate(['/dila/upravit/' + this.item.id], {queryParams: {redirectTo: this.redirectTo, redirectId: this.redirectId}});
  }

  onTransfer() {
    this.dialog.open(ItemChangeContractModalComponent, { data: {id: this.item.id} }).afterClosed().subscribe((result) => {
      if(result) {
        this.onTransferedItem.emit(this.item.id);
      }
    });
  }

  onDelete() {
    this.dialog.open(ConfirmDialog, {
      data: {title: 'Opravdu chcete smazat dílo ze smlouvy?', message: 'Dílo bude odstraněno ze smlouvy zůstane však v seznamu děl.', confirmText: 'Ano'}
      })
      .afterClosed().subscribe(async (result: boolean) => {
      if(result) {
        await firstValueFrom(this.itemService.itemControllerRemoveItemContractRelation(this.item.id));
        this.onDeletedItem.emit(this.item.id);
      }
    })
  }
}
