import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {FileUpload} from 'primeng/fileupload';
import {ClientService} from "../../../../@backend";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'canvas-add-client-file',
  templateUrl: './add-client-file.component.html',
})
export class AddClientFileComponent {

  atleastOneFileUploaded: boolean = false;
  clientId: number;
  file: any;

  uploading: boolean = false;

  form = this.fb.group({
    name: [null, Validators.required],
    description: [null]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClientFileComponent>,
    private fb: FormBuilder,
    private clientService: ClientService
  ) {
    if(data?.clientId) {
      this.clientId = data.clientId;
    }else{
      console.log('Missing client ID');
    }

  }

  valid() {
    if(this.form.valid && this.atleastOneFileUploaded) {
      return true;
    }
  }

  async save() {
    if(this.valid()) {
      this.uploading = true;
      try {
        const res = await firstValueFrom(this.clientService.clientControllerUploadClientFile(this.clientId, this.form.value.name, this.form.value.description, this.file));
        this.uploading = false;
        this.dialogRef.close(res);
      }catch (e) {
        this.uploading = false;
      }

    }
  }

  async processUpload(event: any, uploadButton: FileUpload) {
    for(let file of event.files) {
      this.atleastOneFileUploaded = true;
      this.file = file;
    }
    uploadButton.clear();
  }
}
