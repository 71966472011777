<h1 mat-dialog-title>
  @if (tagId) {
    Úprava štítku
  } @else {
    Přidání nového štítku
  }
</h1>
<div mat-dialog-content>
  <form class="flex flex-col mt-2 w-full place-content-center" [formGroup]="form">
        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Název</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Popis</mat-label>
          <textarea matInput type="text" formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill" [subscriptSizing]="'dynamic'">
          <mat-label>Barva</mat-label>
          <input matInput type="color" formControlName="color">
        </mat-form-field>

        <div class="flex flex-row">
          @if (hexColor) {
            Kód barvy: {{hexColor}}
          }
        </div>
  </form>
</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row-reverse place-content-center items-center">
  <button mat-flat-button color="accent"  class="m-2" (click)="save()" [disabled]="!this.form.valid">
    @if (tagId) {
      Upravit štítek
    } @else {
      Přidat štítek
    }
  </button>
  <button mat-flat-button color="primary" class="m-2" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
