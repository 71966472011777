import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuctionsSelect, ExcelService, TelephonistCollaboratorWithRelations} from '../../../../@backend';
import {MatDialog} from "@angular/material/dialog";
import {
  LimitationAssignTelephonistModalComponent
} from "../limitation-assign-telephonist-modal/limitation-assign-telephonist-modal.component";
import {firstValueFrom} from "rxjs";
@Component({
  selector: 'canvas-limitation-telephonist-card',
  templateUrl: './limitation-telephonist-card.component.html',
})
export class LimitationTelephonistCardComponent implements OnInit {
  @Input() telephonist: TelephonistCollaboratorWithRelations;
  @Input() highlightLastRow: boolean = false;
  @Input() auctionId: number;
  @Output() refresh = new EventEmitter<boolean>();

  constructor(private readonly dialog: MatDialog, private excelService: ExcelService) { }

  ngOnInit(): void {
  }


  checkIfCrossingItem(catalogNumber: number) {
    if (this.telephonist.crossItems.includes(catalogNumber)) {
      return true;
    }else {
      return false;
    }
  }

  checkIfCloseNextItem(catalogNumber: number) {
    if (this.telephonist.crossItemsNext.includes(catalogNumber) || this.telephonist.crossItemsPrev.includes(catalogNumber)) {
      return true;
    }else {
      return false;
    }
  }

  onMouseEnterLimit(telephonistId: string, catalogNumber: number) {
      this.highlightCrossLimit(telephonistId, catalogNumber);
  }

  onMouseLeaveLimit(telephonistId: string, catalogNumber: number) {
    const elements = document.querySelectorAll('[data-catalognumber="'+telephonistId+':'+catalogNumber+'"]');
    if(elements){
      elements.forEach((el) => {
        el.classList.remove('shadow-none', 'scale-125');
      })
    }
  }

  highlightCrossLimit(telephonistId: string, catalogNumber: number) {
    const elements = document.querySelectorAll('[data-catalognumber="'+telephonistId+':'+catalogNumber+'"]');
    if(elements) {
      elements.forEach((el) => {
        el.classList.add('shadow-lg', 'scale-125');
      })
    }
  }


  assignLimitbaseToTelephonist(limitbaseId: number | null, auctionId: number, telephonistId: string | null) {
    this.dialog.open(LimitationAssignTelephonistModalComponent, {
      data: {limitbaseId, auctionId, telephonistId},
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    })
      .afterClosed().subscribe((res: any) => {
        if(res) {
          this.refresh.emit(true);
        }
    });
  }

  async downloadTelephonistExcel(telephonistId: string, auctionId: number) {
    try {
      // const snackbarRef = this.snackBar.open('Excel se generuje...', 'OK', {panelClass: ['bg-green'], horizontalPosition: "right"})
      const res = await firstValueFrom(this.excelService.excelExportControllerExportTelephonistLimitation(telephonistId, auctionId));
      const excelUrl = URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = excelUrl
      link.download = this.telephonist.firstname.toLowerCase() + '_' + this.telephonist.lastname.toLowerCase() + '.xlsx';
      document.body.append(link);
      link.click();
      link.remove();

    } catch (e) {
      console.log(e);
      if(e.status === 404) {
        // this.snackBar.open('Něco se pokazilo, kontaktujte administrátora', 'OK', {duration: 2000, panelClass: ['bg-orange']})
      }
    }
  }
}
