import {Component, Input} from '@angular/core';
import {ClientFileWithRelations, ClientService} from '../../../../@backend';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'canvas-client-files-card',
  templateUrl: './client-files-card.component.html'
})
export class ClientFilesCardComponent {

  @Input() file: ClientFileWithRelations;

  constructor(private clientService: ClientService) {
  }

  async downloadFile() {
    const res = await firstValueFrom(this.clientService.clientControllerDownloadClientFile(this.file.clientId, this.file.filename));
    const excelUrl = URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = excelUrl
    link.download = this.file.filename;
    document.body.append(link);
    link.click();
    link.remove();
  }
}
