<div class="flex flex-row justify-items-stretch items-stretch w-full">
<div class="w-full p-4 md:w-2/5 lg:w-1/4">
  <a class="relative block h-48 overflow-hidden rounded" [routerLink]="'/dila/detail/' + limitation.item.id" routerLinkActive="active">
    <img [alt]="limitation.item.name" loading="lazy" class="block h-full w-full object-cover object-center cursor-pointer"
         [src]='limitation.item.photo|photoSecured:"1":"/plain/rs:fill:250:250:1/g:no/":limitation.item.shared | async' width="250" height="250" />
  </a>
</div>
<div class="p-4 md:w-1/3 lg:w-1/2">
  <div class="mt-4">
    <div>
      <canvas-item-labels [limitNumber]="limitation.limitbase.rowNumber" [itemId]="limitation.item.id" [prefixNumber]="limitation.item?.contract?.prefixNumber" [catalogNumber]="limitation.item.catalogNumber" [auctionNumber]="limitation.item?.auctionterms" [shared]="limitation.item.shared"></canvas-item-labels>
    </div>
    <a [routerLink]="'/dila/detail/' + limitation.item.id" routerLinkActive="active">
      <h2  class="title-font text-lg font-medium text-gray-900 inline" [innerHTML]="limitation.item.name"></h2>
    </a>
    <span *ngIf="limitation.item.datace"> ({{limitation.item.datace}})</span>
    <h3 class="title-font font-medium text-gray-900">
      <kodl-author [authors]="limitation.item?.authors" [showDating]="true"></kodl-author>
    </h3>
    <div class="mt-1">{{limitation.item.technique}}</div>
    <div class="">
      {{limitation.item.height ? limitation.item.height : ''}}{{limitation.item.width  ? ' x ' + limitation.item.width : ''}}{{limitation.item.depth ? ' x ' + limitation.item.depth : ''}} cm
    </div>
    <div class="flex flex-col justify-items-end mt-2">
<!--      <button disabled mat-flat-button class="mat-button-small text-xs w-12 h-8"><mat-icon svgIcon="mat:edit"></mat-icon> Upravit</button>-->
    </div>
  </div>
</div>
<div class="p-4 md:w-1/3 lg:w-1/3 flex flex-col items-center justify-center bg-slate-100 text-center gap-2">
  <a routerLink="/klienti/detail/{{limitation.client.id}}" class="font-bold text-xl hover:underline"><canvas-client-basic [client]="limitation.client"></canvas-client-basic></a>

  <div class="flex flex-row gap-3 p-1 items-center border-b-gray-200 border-b-2 w-full place-content-center">
      <div class="font-bold">Typ: </div>
      <div *ngIf="limitation.type === 1" class="flex items-center">
        <mat-icon svgIcon="mat:phone"></mat-icon> Telefonní limit
      </div>
      <div *ngIf="limitation.type === 2" class="flex items-center">
        <mat-icon svgIcon="mat:back_hand"></mat-icon> Pevný limit
      </div>
  </div>

  <div class="flex flex-row gap-3 items-center border-b-gray-200 border-b-2 p-1 w-full place-content-center">
    <div class="font-bold">Výše limitu: </div>
    <div>
      <span class="bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-amber-50">
        <span *ngIf="limitation.value;else noValue">{{limitation.value| currency : 'CZK' : 'symbol' : '1.0-0'}}</span>
        <ng-template #noValue>
          -
        </ng-template>
      </span>
    </div>
  </div>

  <div class="flex flex-row gap-3 items-center border-b-gray-200 border-b-2 p-1 w-full place-content-center">
    <div class="font-bold">Poznámka: </div>
    <div>
      {{limitation.note}}
    </div>
  </div>

  <div class="flex items-center place-content-center justify-items-stretch gap-1">
    <a routerLink="/limitace/detail/{{limitation.limitbase.id}}" mat-flat-button class="text-xs w-full h-8" matTooltip="Detail limitace"><mat-icon svgIcon="mat:preview"></mat-icon> Detail</a>
    <a (click)="goToLimitationEdit()" mat-flat-button class="text-xs w-full h-8" matTooltip="Upravit limitaci"><mat-icon svgIcon="mat:edit"></mat-icon> Upravit</a>
  </div>


</div>
</div>
