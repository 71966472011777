import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ClientService,
  ModxClient, ModxContracttemplate,
  TemplateService
} from '../../../../@backend';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {firstValueFrom} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {ClientBasicComponent} from "../client-basic/client-basic.component";

@Component({
  selector: 'canvas-client-select',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatSelectModule, MatIconModule, ClientBasicComponent],
  template: `
    @if (!loading) {
    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
      <mat-label>Klient</mat-label>
      <mat-select (valueChange)="onClientSelect($event)" [(ngModel)]="selectedClientId" [placeholder]="'Vyberte klienta'" [required]="required">
        @for (client of clients;track client.id) {
        <mat-option [value]="client.id" class="text-xs">
            <canvas-client-basic [client]="client"></canvas-client-basic>
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  `
})
export class ClientSelectComponent implements OnInit {

  @Output() client = new EventEmitter<ModxClient>();
  @Input() selectedClientId?: number = null;
  @Input() clients?: ModxClient[];
  @Input() required?: boolean = false;


  loading = true;

  constructor(private clientService: ClientService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if(this.clients === undefined) {
      this.clients = await firstValueFrom(this.clientService.clientControllerGetClientsForSelect());
    }
    this.loading = false;

  }

  onClientSelect(clientId: number) {
    const client = this.clients.find(i => i.id === clientId);
    this.client.emit(client);
  }


}
