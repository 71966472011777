import {Component, Inject} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ClientSelectComponent} from "../client-select/client-select.component";
import {ItemService, ModxContractWithRelations} from "../../../../@backend";
import {ClientSelectContractComponent} from "../client-select-contract/client-select-contract.component";
import {firstValueFrom} from "rxjs";

export interface ItemChangeContractModalComponentData {
  contractId: number;
  prefixNumber: string;
}
@Component({
  selector: 'canvas-item-change-contract-modal',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    ClientSelectComponent,
    ClientSelectContractComponent
  ],
  templateUrl: './item-change-contract-modal.component.html',
})
export class ItemChangeContractModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public item: {id: number},
              protected dialogRef: MatDialogRef<ItemChangeContractModalComponent>,
              private itemService: ItemService) {
  }

  save() {

  }

  close() {
    this.dialogRef.close(false);
  }

  async onContractSelected(contract: ModxContractWithRelations) {
    console.log('Item: ', this.item.id)
    console.log('transfer to');
    console.log('Contract: ', contract.id);
    const result = await firstValueFrom(this.itemService.itemControllerTransferItemToContract(this.item.id, contract.id));
    if(result) {
      this.dialogRef.close({contractId: contract.id, prefixNumber: contract.prefixNumber} as ItemChangeContractModalComponentData);
    }
  }
}
