<div class="flex flex-col p-4">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Přesun díla do smlouvy</h2>

    <button class="text-secondary" (click)="close()" mat-icon-button type="button">
      <mat-icon  svgIcon="mat:close"></mat-icon>
    </button>

  </div>
  <mat-divider class="text-border"></mat-divider>
  <div class="flex flex-col p-3 w-full">
      <canvas-client-select-contract (contract)="onContractSelected($event)"  class="w-full"></canvas-client-select-contract>
  </div>

  <div class="flex flex-row-reverse gap-2">
    <button mat-flat-button color="warn" (click)="close()">Zavřít</button>
  </div>
</div>
