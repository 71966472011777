import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ItemBasicComponent} from './item-basic/item-basic.component';
import {PhotoPipe} from '../pipes/photo.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {PhotoSecuredPipe} from '../pipes/photoSecured.pipe';
import {ItemLabelsComponent} from './item-labels/item-labels.component';
import {AuctionSelectComponent} from './auction-select/auction-select.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import {LimitationBasicComponent} from './limitation-basic/limitation-basic.component';
import {AuthorComponent} from './author/author.component';
import {LimitationSummarizeCardComponent} from './limitation-summarize-card/limitation-summarize-card.component';
import {LimitationTelephonistCardComponent} from './limitation-telephonist-card/limitation-telephonist-card.component';
import {ClientBasicComponent} from './client-basic/client-basic.component';
import {ClientBasicCardComponent} from './client-basic-card/client-basic-card.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ClientTagsComponent} from './client-tags/client-tags.component';
import { ClientContractCardComponent } from './client-contract-card/client-contract-card.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ClientLimitationCardComponent} from './client-limitation-card/client-limitation-card.component';
import { LimitationAssignTelephonistModalComponent } from './limitation-assign-telephonist-modal/limitation-assign-telephonist-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {ClientBiditemCardComponent} from "./client-biditem-card/client-biditem-card.component";
import {CountrySelectComponent} from "./country-select/country-select.component";
import {ConfirmDialog} from "./confirm-dialog/confirm-dialog.component";
import {AddClientFileComponent} from "./add-client-file/add-client-file.component";
import {FileUploadModule} from "primeng/fileupload";
import {MatInputModule} from "@angular/material/input";
import {ClientFilesCardComponent} from "./client-files-card/client-files-card.component";
import {LimitationAssignedSelectComponent} from "./limitation-assigned-select/limitation-assigned-select.component";
import {MatRadioModule} from "@angular/material/radio";
import {ContractFilesCardComponent} from "./contract-files-card/contract-files-card.component";
import {PhotoSinglePipe} from "../pipes/photoSingle.pipe";
import {AddressingSelectComponent} from "./addressing-select/addressing-select.component";
import {ItemJobsComponent} from "../../pages/items/item-jobs/item-jobs.component";
import {UserCollaboratorProfileComponent} from "./user-collaborator-profile/user-collaborator-profile.component";
import {TagAddEditModalComponent} from "./tag-add-edit-modal/tag-add-edit-modal.component";

@NgModule({
    declarations: [
        ItemBasicComponent,
        ItemLabelsComponent,
        LimitationBasicComponent,
        LimitationSummarizeCardComponent,
        LimitationTelephonistCardComponent,
        PhotoSinglePipe,
        AuctionSelectComponent,
        ClientBasicCardComponent,
        ClientTagsComponent,
        ClientLimitationCardComponent,
        LimitationAssignTelephonistModalComponent,
        ClientBiditemCardComponent,
        ConfirmDialog,
        AddClientFileComponent,
        ClientFilesCardComponent,
        LimitationAssignedSelectComponent,
        ContractFilesCardComponent,
        TagAddEditModalComponent,
    ],
  exports: [
    ItemBasicComponent,
    ItemLabelsComponent,
    PhotoSecuredPipe,
    PhotoSinglePipe,
    AuctionSelectComponent,
    LimitationBasicComponent,
    LimitationSummarizeCardComponent,
    LimitationTelephonistCardComponent,
    ClientBasicCardComponent,
    ClientTagsComponent,
    ClientContractCardComponent,
    ClientLimitationCardComponent,
    ClientBiditemCardComponent,
    ClientFilesCardComponent,
    LimitationAssignedSelectComponent,
    ContractFilesCardComponent,
    TagAddEditModalComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ChartModule,
    NgOptimizedImage,
    AuthorComponent,
    ClientBasicComponent,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    MatDialogModule,
    MatDividerModule,
    FileUploadModule,
    MatInputModule,
    MatRadioModule,
    ItemJobsComponent,
    PhotoPipe,
    UserCollaboratorProfileComponent,
    PhotoSecuredPipe,
    ClientContractCardComponent,
  ]
})
export class ComponentsModule { }
