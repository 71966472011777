export const jobTypes = {
  photo: 'Fotografování',
  expertise: 'Expertiza',
  restoration: 'Restaurování',
  framing: 'Rámování',
  glasschange: 'Doplnění / Výměna skla',
  reproduction: 'Vytvoření reprodukce',
  describing: 'Popis',
  correction: 'Korektura',
  consultation: 'Konzultace',
}
