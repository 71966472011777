import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  UserManagementService, UserWithRelations
} from '../../../../@backend';

import {firstValueFrom} from 'rxjs';
import {CommonModule} from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PhotoPipe} from "../../pipes/photo.pipe";
import {UserLocalService} from "../../services/userLocal.service";

@Component({
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule, ReactiveFormsModule, PhotoPipe],
  selector: 'canvas-user-select',
  templateUrl: './user-select.component.html',
})
export class UserSelectComponent implements OnInit {

  @Output() user = new EventEmitter<UserWithRelations>();
  @Input() selectUserId?: string | number = null;
  @Input() useModxId?: boolean = true;
  @Input() displayAll?: boolean = true;
  @Input() returnModxId?: boolean = true;
  @Input() label?: string = 'Klienti uživatele:';
  @Input() showRoles?: boolean = false;
  @Input() defaultOption?: string = 'Všichni uživatelé';
  @Input() defaultHint?: string | undefined = undefined;


  loading = true;
  users: Array<UserWithRelations>;
  selectedUser: UserWithRelations;

  constructor(private userService: UserManagementService, private userLocalService: UserLocalService,) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    // this.users = await firstValueFrom(this.userService.userManagementControllerGetUsersForSelect(this.showRoles));
    this.users = await this.userLocalService.getUsers();
    if(this.selectUserId) {
      if(this.useModxId) {
        this.selectedUser = this.users.find(i => i.modxUserId === this.selectUserId);
      }else {
        this.selectedUser = this.users.find(i => i.id === this.selectUserId);
      }
    }else if(this.selectUserId === 0){
      this.selectedUser = null;
      this.selectUserId = null;
    }
    this.loading = false;
  }


  onUserSelect(userId: number | string) {
    let foundUser = undefined;
    if(this.useModxId) {
      foundUser = this.users.find(i => i.modxUserId === userId);
    }else {
      foundUser = this.users.find(i => i.id === userId);
    }
    if(foundUser) {
      this.selectedUser = foundUser;
      this.user.emit(foundUser);
    }else {
      this.user.emit(null);
    }
  }


}
