import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModxClient, ModxClientWithRelations} from '../../../../@backend';

@Component({
  selector: 'canvas-client-basic',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span>{{ client?.title_1 }} {{ client?.firstname }} {{ client?.lastname }} {{ client?.title_2 }}</span>
    @if(client?.company) {
      <div class="font-light text-sm">{{client.company}}
        @if(client?.ico && client?.ico !== 0 && client?.ico !== '0') {
          <span> (IČO: {{client.ico}})</span>
        }
      </div>
    }
  `
})
export class ClientBasicComponent {
  @Input() client: ModxClient | ModxClientWithRelations | any;
  @Input() enableLink: boolean = false;

}
