import {Component, Input} from '@angular/core';
import {ModxContractWithRelations, ModxLimitbaseWithRelations} from '../../../../@backend';

@Component({
  selector: 'canvas-client-limitation-card',
  templateUrl: './client-limitation-card.component.html'
})
export class ClientLimitationCardComponent {

  @Input() limitbase: ModxLimitbaseWithRelations;
}
