<h1 mat-dialog-title>Nahrání souboru ke klientovi</h1>
<div mat-dialog-content>
    <div class="flex flex-col items-center m-2 place-content-center">
        <p-fileUpload
          #clientFileUpload
          mode="basic"
          [customUpload]="true"
          (uploadHandler)="processUpload($event, clientFileUpload)"
          [name]="'auctionfile'"
          [auto]="true"
          accept="image/*,application/pdf"
          chooseLabel="Nahrát soubor">
        </p-fileUpload>
      @if (file) {
        <div>{{file?.name}}</div>
      }
    </div>
  <form class="flex flex-col mt-2 w-full place-content-center" [formGroup]="form">
        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Název</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Popis</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
  </form>
</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row-reverse place-content-center items-center">
  <button mat-flat-button color="accent"  class="m-2" (click)="save()" [disabled]="!valid() || uploading">Přidat</button>
  <button mat-flat-button color="primary" class="m-2" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
