import {Component, Input, OnInit} from '@angular/core';
import {ModxClient, ModxClientWithRelations} from '../../../../@backend';

@Component({
  selector: 'canvas-client-basic-card',
  template: `
    <div class="flex flex-row justify-between w-full p-2">
      <div class="flex flex-col">
        <a class="text-xl text-blue-400 hover:underline" [routerLink]="'/klienti/detail/' + client.id">
          @if(client.firstname && client.lastname) {
            {{client.title_1}} {{client.firstname}} {{client.lastname}} {{client.title_2}}
          }@else {
            {{client.company}}
          }
        </a>
        <div>
        {{client.address}} @if(client.city) {<span>, {{client.city}}</span>}
        </div>
        <div class="flex flex-row text-right gap-2">
          @if(this.client?.attender1) {
            <canvas-user-collaborator-profile [type]="'modUser'" [user]="this.client.attender1" [avatarOnly]="true"></canvas-user-collaborator-profile>
          }
          @if(this.client?.attender2) {
            <canvas-user-collaborator-profile [type]="'modUser'" [user]="this.client.attender2" [avatarOnly]="true"></canvas-user-collaborator-profile>
          }
        </div>
      </div>
      @if(client?.tags) {
        <canvas-client-tags class="flex flex-row-reverse gap-0.5 items-center w-1/2 flex-wrap" [tags]="client.tags"></canvas-client-tags>
      }
    </div>

  `
})
export class ClientBasicCardComponent implements OnInit {
  @Input() client: ModxClientWithRelations;

  constructor() { }

  ngOnInit(): void {
  }


}
