import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {firstValueFrom} from "rxjs";
import {LimitationService, TelephonistCollaboratorWithRelations} from "../../../../@backend";

@Component({
  selector: 'canvas-limitation-assign-telephonist-modal',
  templateUrl: './limitation-assign-telephonist-modal.component.html'
})
export class LimitationAssignTelephonistModalComponent implements OnInit{
  loading: boolean = false;
  extraLimitbase: number | null;
  auctionId: number;
  telephonistId: string | null = null;
  telephonists: any[];
  telephonistWithLimits: TelephonistCollaboratorWithRelations;


  constructor(@Inject(MAT_DIALOG_DATA) public data: {limitbaseId: number | null, auctionId: number, telephonistId: string | null},
              private dialogRef: MatDialogRef<LimitationAssignTelephonistModalComponent>,
              private limitationService: LimitationService) {

    this.extraLimitbase = data.limitbaseId;
    this.telephonistId = data.telephonistId;
    this.auctionId = data.auctionId;
  }

  async onTelephonistSelect(telephonistId: string | undefined | null) {
    if(telephonistId) {
      this.telephonistId = telephonistId;
      this.telephonistWithLimits = await firstValueFrom(this.limitationService.limitationsControllerGetSingleTelephonistCrossLimits(this.auctionId, this.telephonistId, this.extraLimitbase));
    }else {
      this.telephonistId = null;
      this.telephonistWithLimits = undefined;
    }
  }
  async ngOnInit() {
    this.telephonists = await firstValueFrom(this.limitationService.limitationsControllerGetAuctionTelephonistsForSelect(this.auctionId));
  }

  async save() {
    const res = await firstValueFrom(this.limitationService.limitationsControllerAssignTelephonistToLimitbase(this.extraLimitbase, this.telephonistId))
    if(res) {
      this.dialogRef.close(true);
    }
  }
}
