<div class="flex flex-row w-full border-b border-b-slate-300 hover:bg-gray-100 p-1 h-10 justify-between">
  <div class="flex flex-row items-center gap-3">
    <div class="flex flex-row items-center gap-1">
        <mat-icon svgIcon="mat:attach_file"></mat-icon>
        <a (click)="downloadFile()" class="font-light text-blue-400 hover:underline cursor-pointer">{{file.name}}</a>
    </div>
  </div>
  <div class="flex flex-row items-center gap-2 pr-2">
    <canvas-user-collaborator-profile [type]="'User'" [user]="file.user"></canvas-user-collaborator-profile>
    <div>{{file.datetime | date : 'dd.MM.yyyy HH:mm'}}</div>
  </div>
</div>
