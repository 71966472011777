import {Component, Input} from '@angular/core';
import {
  ClientService, ContractService,
  ModxContractfileWithRelations,
} from '../../../../@backend';
import {openLink} from "../../utils/openLink";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'canvas-contract-files-card',
  templateUrl: './contract-files-card.component.html'
})
export class ContractFilesCardComponent {

  @Input() file: ModxContractfileWithRelations;

  constructor(private contractService: ContractService) {
  }

  async downloadFile() {
    const res = await firstValueFrom(this.contractService.contractControllerGetContractPdf(this.file.contractId, this.file.url));
    openLink(res, undefined, false, '_blank');
  }
}
